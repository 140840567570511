@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
  }

  .form-inner .ant-select,
  .form-inner .ant-picker {
    margin: 0 !important;
  }

  .form-inner .ant-select-selector,
  .form-inner .ant-picker {
    padding: 0 !important;
  }

  .box {
    @apply rounded;
  }

  .box-testo {
    @apply bg-white rounded-3xl px-7 py-5;
    box-shadow: 0px 4px 15px 0px rgba(18, 163, 186, 0.15);
  }

  .box-g {
    @apply min-w-full
        h-32
        min-h-full
        rounded;
  }

  .qs-start:after {
    background-image: url("/static/icons/qs.svg");
    background-repeat: no-repeat;
    background-position: left center;
    content: " ";
    width: 14px;
    margin-top: 5px;
    transform: rotate(180deg);
  }
  .qs-start {
    @apply pl-3 relative;
    background-image: url("/static/icons/qs.svg");
    background-repeat: no-repeat;
    background-position: left center;
  }

  .tab-offer .ant-menu-item.ant-menu-item-selected .ant-menu-title-content {
    color: #000;
    @apply font-medium;
  }
  .tab-offer .ant-menu-item:hover .ant-menu-title-content {
    color: #000;
    @apply font-medium;
  }
  .tab-offer .ant-menu-item:hover:after {
    @apply border-green !important;
  }
  .tab-offer .ant-menu-item.ant-menu-item-selected:after {
    @apply border-green !important;
  }
  .tab-offer .ant-menu-item .ant-menu-title-content {
    @apply px-3 flex;
  }

  .divider-base-f .ant-divider-inner-text {
    @apply font-normal text-sm;
  }

  .xlrounded-important {
    @apply rounded-2xl !important;
  }

  .related-offers .slick-next {
    right: -5px !important;
  }

  .related-offers .slick-prev {
    left: 0px !important;
    z-index: 100;
  }

  .btn.btn-small {
    @apply h-auto py-1 text-xs;
  }

  .btn-orange {
    @apply bg-orange border-orange rounded-2xl text-white h-auto py-2;
  }
  .btn-orange:disabled {
    @apply bg-selago  !important;
  }
  .btn-orange:focus {
    @apply bg-orangeDark border-orangeDark;
  }
  .btn-orange:hover {
    @apply bg-orangeDark border-orangeDark;
  }

  .btn-orange-out:hover {
    @apply bg-orange border-orange;
  }

  .btn-orange-out {
    @apply border border-orange text-orange border-orange rounded-2xl h-auto py-2;
  }

  .shadow-i {
    box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.1);
  }

  .check-lists .ant-checkbox-wrapper span {
    @apply flex !important;
  }

  .check-lists .ant-checkbox-wrapper {
    @apply text-sm flex justify-between flex-row-reverse !important;
  }

  .btn-green:hover {
    @apply bg-green/50 border-green/50 text-white !important;
  }

  .btn-green {
    @apply bg-green text-white rounded-xl border-green px-6;
  }

  .btn-out-green {
    @apply bg-white text-green rounded-xl border-green  space-x-2;
  }

  .room-badge {
    @apply bg-lightOrange rounded-2xl text-darkBlue px-5 py-1;
  }

  .green-badge {
    @apply bg-lightBlueGreen rounded-2xl text-green px-5 py-1 text-xs;
  }

  .btn-circle {
    @apply flex items-center justify-center;
  }

  .btn-circle:hover {
    @apply border-orange/50;
  }

  .my-0i {
    @apply my-0 !important;
  }

  .tag-orange {
    @apply bg-orange border-orange rounded-xl text-white text-sm py-1 px-4 font-medium;
  }
  .tag-orange2 {
    @apply bg-orange/20 border-0 rounded-xl text-orange text-sm py-1 px-4 font-medium;
  }

  .social-fade {
    @apply rounded-full bg-lightBlueGreen w-8 h-8 flex items-center justify-center;
  }

  .box-service-s {
    @apply rounded-2xl bg-org-30;
    box-shadow: 0px 4px 50px rgba(18, 163, 186, 0.2);
  }

  .testo-box {
    @apply bg-white p-10 rounded-2xl;
    box-shadow: 0px 4px 20px rgba(120, 123, 154, 0.15);
  }

  .testo-box:before {
    content: " ";
    @apply absolute left-5 right-0 -top-5 w-full h-full  border  border-green rounded-2xl;
  }

  .circleorange {
    @apply w-5 h-5  border-4 border-orange rounded-full;
  }

  .ant-collapse {
    @apply bg-white !important;
  }

  .ant-collapse:last-child {
    border-bottom: 0 !important;
  }

  .collapse-mov .ant-collapse-content {
    border-radius: 0 0 20px 20px !important;
  }
  .collapse-list .ant-collapse-item {
    @apply border-0 bg-white mb-5;
    box-shadow: 0px 4px 20px rgba(120, 123, 154, 0.15);
    border-radius: 20px !important;
  }

  .ant-collapse-item-active.collapse-mov {
    @apply bg-lightBlueGreen;
  }

  .base-blur {
    backdrop-filter: blur(90px);
  }

  .center-slider .slick-slide.slick-center .highlight-show {
    @apply flex text-white p-5;
    backdrop-filter: blur(6px);
    opacity: 0.8;
  }

  .slick-slide:not(.slick-center) .imageeffect {
    @apply bg-darkBlue/60;
  }

  .auth-modal .ant-modal-body {
    @apply p-3;
  }
  .auth-modal .ant-modal-content {
    @apply rounded-2xl;
  }

  .active-login {
    @apply bg-green p-2 rounded-3xl text-white !important;
  }
  .login-tab {
    @apply p-2 rounded-3xl text-gray-50 cursor-pointer;
  }
  .input-bold.ant-picker {
    @apply px-3 py-0 my-0 !important;
  }
  .input-bold .ant-picker-input input {
    @apply font-medium;
  }
  .modal-base .ant-modal-content {
    @apply rounded-2xl;
  }

  @media (max-width: 620px) {
    .swiper-blog-slider .swiper {
      width: fit-content;
      max-width: 80%;
      height: 350px;
      padding: 0 !important;
    }
  }

  @media (max-width: 1000px) {
    .search-list .ant-spin-container {
      @apply grid grid-cols-2;
    }
  }

  .overview-item {
    @apply py-5;
  }
}
.swiper-blog-slider {
}

.swiper-blog-slider #app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-blog-slider .swiper {
  width: 380px;
  height: 470px;
  padding: 0 !important;
}

.swiper-blog-slider .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}

.top-78 {
  top: 480px !important;
}

.Swiper-landing-page .swiper {
  width: 100%;
  height: 100%;
}

.Swiper-landing-page .mySwiper {
  padding: 10px 0;
}

.Swiper-landing-page .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.Swiper-landing-page .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dis-min-h {
  min-height: 100px;
}

.ant-select-selection-placeholder {
  color: rgba(43, 45, 66);
}

#dpahde44 .swiper-pagination {
  right: -60%;
  transform: translateX(-0%);
  white-space: nowrap;
  left: auto;
}

.filter-side-search .ant-list-items {
  @apply space-y-5;
}

.place-h-m input {
  @apply placeholder:text-[16px] placeholder:font-[500] placeholder:text-[#212121];
}
.book-dropdown .ant-select-selection-placeholder {
  @apply text-[16px] font-[500] text-[#212121];
}
.offer-picker-r .ant-picker-input:nth-child(2) {
  background: red;
}
.circle-checkbox .ant-checkbox-inner,
.circle-checkbox .ant-checkbox-input {
  @apply focus:!rounded-full;
}
.circle-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.circle-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.circle-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  @apply !rounded-full;
}

.article-comment {
  border: 1px solid #ececf6 !important;
}

textarea.article-comment {
  padding: 16px !important;
}

.article-comment {
  border: 1px solid #ececf6 !important;
}

textarea.article-comment {
  padding: 16px !important;
}
.blog-content span {
  @apply !text-[16px];
}
.blog-content img {
  @apply !rounded-2xl !my-4;
}
.blog-content h1,
.blog-content h2,
.blog-content h2 span,
.blog-content h3,
.blog-content h4 {
  @apply !text-[28px] !font-semibold;
}
